@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  font-family: 'Noto Sans JP',sans-serif;
}

body{
  button{
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
  }
  p{
    margin: 0;
    padding: 0;
  }
}

#canvas-container {
  position: relative;
  margin: auto;
  width: 640px;
  height: 640px;
  pointer-events: auto;
}

.controls {
  position: absolute;
  top: 133px;
  left: 10px;
  z-index: 20;
  width: 180px;
  .controls__btns{
    button{
      display: block;
    }
    .selectSHapes{
      width: 100%;
      line-height: 40px;
      padding-left: 12px;
      margin-top: 4px;
      font-size: 18px;
      border: 1px solid #C1D7E5;
      background-color: #fff;
      border-radius: 4px;
      font-weight: 500;
      text-align: left;
      span{
        position: relative;
        padding-left: 32px;
        &::before{
          position: absolute;
          content: "";
          width:24px;
          height: 24px;
          background-size: 100%;
          background-repeat: no-repeat;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      &.selectSHapes--triangle{
        span::before{
          background-image: url('/public/path/to/btn_shape01.png');
        }
      }
      &.selectSHapes--rightTriangle{
        span::before{
          background-image: url('/public/path/to/btn_shape02.png');
        }
      }
      &.selectSHapes--rect{
        span::before{
          background-image: url('/public/path/to/btn_shape03.png');
        }
      }
      &.selectSHapes--trapezoid{
        span::before{
          background-image: url('/public/path/to/btn_shape04.png');
        }
      }
      &.selectSHapes--specialTrapezoid{
        span::before{
          background-image: url('/public/path/to/btn_shape05.png');
        }
      }
      &.selectSHapes--parallelogram{
        span::before{
          background-image: url('/public/path/to/btn_shape06.png');
        }
      }
      &.selectSHapes--rhombus{
        span::before{
          background-image: url('/public/path/to/btn_shape07.png');
        }
      }
    }
  }
  .editArea{
    /* position: absolute; */
    width: 180px;
    bottom: 20px;
    .editArea__btn{
      margin-top: 8px;
      width: 100%;
      line-height: 52px;
      font-size: 20px;
      font-weight: 700;
      padding-left: 12px;
      border-radius: 4px;
      text-align: left;
      background-color: #fff;
      border: 1px solid #C1D7E5;
      span{
        position: relative;
        padding-left: 32px;
        &::before{
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          background-size: 100%;
          background-repeat: no-repeat;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      &.editArea__btn--export{
        span::before{
          background-image: url('/public/path/to/btn_export01.png');
        }
      }
      &.editArea__btn--measurement{
        span::before{
          background-image: url('/public/path/to/btn_measurement01.png');
        }
      }
    }
  }
  .controls__deleteBtn{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}


.exportArea{
  display: inline-block;
  position: relative;
  .canvas-container{
    background-color: #fff;
    border-radius: 8px;
  }
}

.shapes{
  position: relative;
  z-index: 10;
}

.map{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 580px;
  /* height: 580px; */
}



.App{
  display: flex;
  line-height: 1.6;
  letter-spacing: 0.8px;
  &.is_disable{
    .editArea__btn--export,
    .selectSHapes{
      pointer-events: none;
      background-color: #F0F2F5;
      border: 1px solid #C1D7E5;
      color: #9599A0;
    }
    .App__menuArea {
      .selectpref{
        pointer-events: none;
        background-color: #F0F2F5;
        border: 1px solid #C1D7E5;
        color: #9599A0;
      }
      .editArea{
        .editArea__btn--export{
          background-color: #F0F2F5;
          span{
            &::before{
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
  &.no_select{
    .selectSHapes{
      pointer-events: none;
      background-color: #F0F2F5;
      border: 1px solid #C1D7E5;
      color: #9599A0;
    }
    .editArea{
      .editArea__btn{
        pointer-events: none;
        background-color: #F0F2F5;
        color: #9599A0;
        span{
            &::before{
              opacity: 0.4;
            }
          }
        }
    }
  }
  .App__menuArea{
    position: relative;
    width: 200px;
    height: 100vh;
    background-color: #EBF4FA;
    padding: 20px 10px;
    button{
      width: 180px;
    }
    .selectpref{
      line-height: 60px;
      z-index: 20;
      background-color: #007CBA;
      color: #fff;
      text-align: center;
      border: 1px solid #C1D7E5;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 700;
    }
    .headLine{
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
    }
  }
  .editArea{
    margin-top: 32px;
    width: 180px;
    bottom: 20px;
    .editArea__btn{
      margin-top: 8px;
      width: 100%;
      line-height: 52px;
      font-size: 20px;
      font-weight: 700;
      padding-left: 12px;
      border-radius: 4px;
      text-align: left;
      background-color: #fff;
      border: 1px solid #C1D7E5;
      span{
        position: relative;
        padding-left: 32px;
        &::before{
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          background-size: 100%;
          background-repeat: no-repeat;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      &.editArea__btn--export{
        span::before{
          background-image: url('/public/path/to/btn_export01.png');
        }
      }
      &.editArea__btn--measurement{
        span::before{
          background-image: url('/public/path/to/btn_measurement01.png');
        }
      }
    }
  }
  .App__canvasArea{
    width: 100%;
    background-color: #F5F5F5;
    padding-top: 45px;
    padding-left: 40px;
    .selectInfo{
      position: absolute;
      top: 20px;
      left: 220px;
      font-size: 16px;
      z-index: 10;
      display: flex;
      align-items: flex-end;
      gap: 20px;
      p{
        line-height: 1;
        span{
          font-weight: bold;
        }
        .selectInfo__number{
          font-size: 20px;
          color: #007CBA;
          &.low{
            color: #F20000;
          }
        }
      }
    }
  }


  #overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    #modalContent{
      position: relative;
      width: 660px;
      padding: 60px;
      border-radius: 20px;
      background-color: #fff;
      .closeBtn{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 24px;
        height: 24px;
        background-image: url('/public/path/to/btn_close01.png');
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 101;
      }
      .btnArea{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        label{
          display: block;
          font-weight: 700;
          text-align: center;
          width: calc(25% - 15px);
          line-height: 60px;
          font-size: 16px;
          border-radius: 4px;
          background: #EBF4FA;
          input{
            display: none;
          }
        }
      }
      .headArea{
        font-weight: 700;
        text-align: center;
        .headArea__title{
          font-size: 32px;
          color: #007CBA;
        }
        .headArea__text{
          margin-top: 14px;
          font-size: 20px;
        }
      }
      .checkBtnArea{
        display: flex;
        gap: 40px;
        justify-content: center;
        margin-top: 34px;
        .checkBtnArea__btn{
          width: 180px;
          line-height: 60px;
          text-align: center;
          font-weight: 700;
          font-size: 20px;
          border-radius: 4px;
          background-color: #007CBA;
          color: #fff;
          &.checkBtnArea__btn--border{
            color: #007CBA;
            background-color: #fff;
            border: 1px solid #007CBA;
          }
        }
      }
    }
  }

  .measuringArea{
    position: fixed;
    top: 40px;
    left: 240px;
    z-index: 100;
    width: 1000px;
    height: 590px;
    border-radius: 8px;
    .measuringArea__text{
      position: absolute;
      top: -30px;
      left: 110px;
      color: #007CBA;
      font-size: 20px;
      font-weight: 700;
    }
  }
}